export enum RoleName {
  PractiseOwner = "practice_owner",
  PractiseManager = "practise_manager",
  DoctorWho = "doctor-who",
  Practitioner = "practicioner",
  StaffMember = "staff_member",
}

export enum Action {
  // View pages
  ViewDashboard = "VIEW_DASHBOARD",
  ViewPurchaseOrders = "VIEW_PURCHASE_ORDERS",
  ViewRFQs = "VIEW_RFQS",
  ViewStock = "VIEW_STOCK",
  ViewMergeStockItems = "VIEW_MERGE_STOCK_ITEMS",
  ViewVendors = "VIEW_VENDORS",
  ViewAppSettings = "VIEW_APP_SETTINGS",

  // Purchase Orders
  CreateOrder = "CREATE_ORDER",
  SetOnFollowUp = "SET_ON_FOLLOW_UP",
  CompleteOrder = "COMPLETE_ORDER",

  // RFQs
  AddVendorToRFQ = "ADD_VENDOR_TO_RFQ",
  CloneRFQ = "CLONE_RFQ",
  CreateRFQ = "CREATE_RFQ",
  EditRFQ = "EDIT_RFQ",
  SendEmailToVendor = "SEND_EMAIL_TO_VENDOR",
  PurchaseOrder = "PURCHASE_ORDER",

  // Stock
  CreateFolder = "CREATE_FOLDER",
  EditFolder = "EDIT_FOLDER",
  MoveToFolder = "MOVE_TO_FOLDER",
  UploadStockItem = "UPLOAD_STOCK_ITEM",
  UpdateStockQuantity = "UPDATE_STOCK_QUANTITY",
  TakeStockItem = "TAKE_STOCK_ITEM",
  EditStockItem = "EDIT_STOCK_ITEM",

  // Vendors
  CreateVendor = "CREATE_VENDOR",
  EditVendor = "EDIT_VENDOR",
  DeleteVendor = "DELETE_VENDOR",

  // Reports
  ViewReports = "VIEW_REPORTS",

  // App Settings
  EditAppSettings = "EDIT_APP_SETTINGS",
}

const permissions: { [key in RoleName]?: Record<Action, number> } = {
  [RoleName.PractiseOwner]: {
    [Action.ViewDashboard]: 1,
    [Action.ViewPurchaseOrders]: 1,
    [Action.ViewRFQs]: 1,
    [Action.ViewStock]: 1,
    [Action.ViewMergeStockItems]: 1,
    [Action.ViewVendors]: 1,
    [Action.ViewAppSettings]: 1,

    // Purchase Orders
    [Action.CreateOrder]: 1,
    [Action.SetOnFollowUp]: 1,
    [Action.CompleteOrder]: 1,

    // RFQs
    [Action.AddVendorToRFQ]: 1,
    [Action.CloneRFQ]: 1,
    [Action.CreateRFQ]: 1,
    [Action.EditRFQ]: 1,
    [Action.SendEmailToVendor]: 1,
    [Action.PurchaseOrder]: 1,

    // Stock
    [Action.CreateFolder]: 1,
    [Action.EditFolder]: 1,
    [Action.MoveToFolder]: 1,
    [Action.UploadStockItem]: 1,
    [Action.EditStockItem]: 1,
    [Action.UpdateStockQuantity]: 1,
    [Action.TakeStockItem]: 1,

    // Vendors
    [Action.CreateVendor]: 1,
    [Action.EditVendor]: 1,
    [Action.DeleteVendor]: 1,

    // Reports
    [Action.ViewReports]: 1,

    // App Settings
    [Action.EditAppSettings]: 1,
  },
  [RoleName.PractiseManager]: {
    [Action.ViewDashboard]: 1,
    [Action.ViewPurchaseOrders]: 1,
    [Action.ViewRFQs]: 1,
    [Action.ViewStock]: 1,
    [Action.ViewMergeStockItems]: 1,
    [Action.ViewVendors]: 1,
    [Action.ViewAppSettings]: 1,

    // Purchase Orders
    [Action.CreateOrder]: 1,
    [Action.SetOnFollowUp]: 1,
    [Action.CompleteOrder]: 1,

    // RFQs
    [Action.AddVendorToRFQ]: 1,
    [Action.CloneRFQ]: 1,
    [Action.CreateRFQ]: 1,
    [Action.EditRFQ]: 1,
    [Action.SendEmailToVendor]: 1,
    [Action.PurchaseOrder]: 1,

    // Stock
    [Action.CreateFolder]: 1,
    [Action.EditFolder]: 1,
    [Action.MoveToFolder]: 1,
    [Action.UploadStockItem]: 1,
    [Action.EditStockItem]: 1,
    [Action.UpdateStockQuantity]: 1,
    [Action.TakeStockItem]: 1,

    // Vendors
    [Action.CreateVendor]: 1,
    [Action.EditVendor]: 1,
    [Action.DeleteVendor]: 1,

    // Reports
    [Action.ViewReports]: 1,

    // App Settings
    [Action.EditAppSettings]: 1,
  },
  // [RoleName.DoctorWho]: {
  //   [Action.ViewDashboard]: 0,
  //   [Action.ViewPurchaseOrders]: 0,
  //   [Action.ViewRFQs]: 0,
  //   [Action.ViewStock]: 0,
  //   [Action.ViewMergeStockItems]: 0,
  //   [Action.ViewVendors]: 0,
  //   [Action.ViewAppSettings]: 0,

  //   // Purchase Orders
  //   [Action.CreateOrder]: 0,
  //   [Action.SetOnFollowUp]: 0,
  //   [Action.CompleteOrder]: 0,

  //   // RFQS
  //   [Action.AddVendorToRFQ]: 0,
  //   [Action.CloneRFQ]: 0,
  //   [Action.CreateRFQ]: 0,
  //   [Action.EditRFQ]: 0,
  //   [Action.SendEmailToVendor]: 0,

  //   // Stock
  //   [Action.CreateFolder]: 0,
  //   [Action.EditFolder]: 0,
  //   [Action.MoveToFolder]: 0,
  //   [Action.UploadStockItem]: 0,
  //   [Action.EditStockItem]: 0,
  //   [Action.UpdateStockQuantity]: 0,
  //   [Action.TakeStockItem]: 0,

  //   // Vendors
  //   [Action.CreateVendor]: 0,
  //   [Action.EditVendor]: 0,
  //   [Action.DeleteVendor]: 0,

  //   // Reports
  //   [Action.ViewReports]: 0,

  //   // App Settings
  //   [Action.EditAppSettings]: 0,
  // },
  [RoleName.Practitioner]: {
    [Action.ViewDashboard]: 1,
    [Action.ViewPurchaseOrders]: 0,
    [Action.ViewRFQs]: 1,
    [Action.ViewStock]: 0,
    [Action.ViewMergeStockItems]: 0,
    [Action.ViewVendors]: 0,
    [Action.ViewAppSettings]: 0,

    // Purchase Orders
    [Action.CreateOrder]: 0,
    [Action.SetOnFollowUp]: 0,
    [Action.CompleteOrder]: 0,

    // RFQs
    [Action.AddVendorToRFQ]: 1,
    [Action.CloneRFQ]: 1,
    [Action.CreateRFQ]: 1,
    [Action.EditRFQ]: 1,
    [Action.SendEmailToVendor]: 0,
    [Action.PurchaseOrder]: 0,

    // Stock
    [Action.CreateFolder]: 0,
    [Action.EditFolder]: 0,
    [Action.MoveToFolder]: 0,
    [Action.UploadStockItem]: 0,
    [Action.EditStockItem]: 0,
    [Action.UpdateStockQuantity]: 0,
    [Action.TakeStockItem]: 0,

    // Vendors
    [Action.CreateVendor]: 0,
    [Action.EditVendor]: 0,
    [Action.DeleteVendor]: 0,

    // Reports
    [Action.ViewReports]: 0,

    // App Settings
    [Action.EditAppSettings]: 0,
  },
  [RoleName.StaffMember]: {
    [Action.ViewDashboard]: 1,
    [Action.ViewPurchaseOrders]: 1,
    [Action.ViewRFQs]: 1,
    [Action.ViewStock]: 1,
    [Action.ViewMergeStockItems]: 1,
    [Action.ViewVendors]: 0,
    [Action.ViewAppSettings]: 0,

    // Purchase Orders
    [Action.CreateOrder]: 0,
    [Action.SetOnFollowUp]: 1,
    [Action.CompleteOrder]: 1,

    // RFQs
    [Action.AddVendorToRFQ]: 0,
    [Action.CloneRFQ]: 1,
    [Action.CreateRFQ]: 1,
    [Action.EditRFQ]: 1,
    [Action.SendEmailToVendor]: 0,
    [Action.PurchaseOrder]: 0,

    // Stock
    [Action.CreateFolder]: 1,
    [Action.EditFolder]: 1,
    [Action.MoveToFolder]: 1,
    [Action.UploadStockItem]: 1,
    [Action.EditStockItem]: 1,
    [Action.UpdateStockQuantity]: 1,
    [Action.TakeStockItem]: 1,

    // Vendors
    [Action.CreateVendor]: 0,
    [Action.EditVendor]: 0,
    [Action.DeleteVendor]: 0,

    // Reports
    [Action.ViewReports]: 0,

    // App Settings
    [Action.EditAppSettings]: 0,
  },
};

const isRoleName = (role: string): role is RoleName => {
  return Object.values(RoleName).includes(role as RoleName);
};

const hasPermission = (roles: string[], action?: Action) => {
  return roles.reduce((acc, role: string) => {
    if (isRoleName(role)) {
      const rolePermissions = permissions[role];
      if (!rolePermissions && !acc) {
        return false;
      }
      if (!acc) {
        // @ts-expect-error
        return Boolean(rolePermissions?.[action]);
      }
    }

    return acc;
  }, false);
};

export default hasPermission;

import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";

import Loader from "components/Loader/Loader"; // Assuming you have a Loader component

const AddNewRFQItemMobilePage = lazy(
  () => import("features/RFQ/pages/AddNewRFQItemMobilePage")
);
const AppSettingsPage = lazy(
  () => import("features/AppSettings/pages/AppSettingsPage")
);
const DashboardPage = lazy(
  () => import("features/Dashboard/pages/DashboardPage")
);
const ErrorPage = lazy(() => import("pages/ErrorPage/ErrorPage"));
const LowStockPage = lazy(() => import("features/Stock/pages/LowStockPage"));

const OrderPage = lazy(() => import("features/Orders/pages/OrderPage"));
const OrderReportsPage = lazy(
  () => import("features/Reports/pages/OrderReportsPage")
);
const OrdersPage = lazy(() => import("features/Orders/pages/OrdersPage"));
const RFQPage = lazy(() => import("features/RFQ/pages/RFQPage"));
const RFQReceivedPage = lazy(
  () => import("features/RFQ/pages/RFQReceivedPage")
);
const RFQsPage = lazy(() => import("features/RFQ/pages/RFQsPage"));
const StaffSchedulePage = lazy(
  () => import("features/StaffSchedule/pages/StaffSchedulePage")
);
const StockDetailsPage = lazy(
  () => import("features/Stock/pages/StockDetailsPage")
);
const StockFolderPage = lazy(
  () => import("features/Stock/pages/StockFolderPage")
);
const StockMobileSearchPage = lazy(
  () => import("features/Stock/pages/StockMobileSearchPage")
);
const StockPage = lazy(() => import("features/Stock/pages/StockPage"));
const StockAuditSummariesPage = lazy(
  () => import("features/Stock/pages/StockAuditSummariesPage")
);
const StockAuditPage = lazy(
  () => import("features/Stock/pages/StockAuditPage")
);
const StockReportsPage = lazy(
  () => import("features/Reports/pages/StockReportsPage")
);
const StockServerSearchResultsPage = lazy(
  () => import("features/Stock/pages/StockServerSearchResultsPage")
);
const UserPage = lazy(() => import("features/ViewUser/pages/UsersPage"));
const VendorPage = lazy(() => import("features/Vendors/pages/VendorPage"));
const VendorsPage = lazy(() => import("features/Vendors/pages/VendorsPage"));

const RoutesComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* TABLET, DESKTOP */}
        <Route path="/" element={<DashboardPage />} />
        <Route path="/callback" element={<DashboardPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />

        <Route path="orders" element={<OrdersPage />} />
        <Route path="orders/:id" element={<OrderPage />} />

        <Route path="rfqs" element={<RFQsPage />} />
        <Route path="rfqs/:id" element={<RFQPage />} />
        <Route path="rfqs/:id/received" element={<RFQReceivedPage />} />

        <Route path="stock" element={<StockPage />} />
        <Route path="stock-audits" element={<StockAuditSummariesPage />} />
        <Route path="stock-audits/:id" element={<StockAuditPage />} />
        <Route
          path="stock/search/:searchTerm"
          element={<StockServerSearchResultsPage />}
        />
        <Route path="stock/item/:id" element={<StockDetailsPage />} />
        <Route path="stock/:name/:id" element={<StockFolderPage />} />
        <Route path="low-stock" element={<LowStockPage />} />

        <Route path="vendors" element={<VendorsPage />} />
        <Route path="vendors/:id" element={<VendorPage />} />

        <Route path="reports-stock" element={<StockReportsPage />} />
        <Route path="reports-orders" element={<OrderReportsPage />} />

        <Route path="app-settings" element={<AppSettingsPage />} />
        <Route path="user" element={<UserPage />} />

        <Route path="error/:errorCode" element={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="staff-schedule" element={<StaffSchedulePage />} />

        {/* MOBILE ROUTES */}
        <Route
          path="/mobile/stock-search"
          element={<StockMobileSearchPage />}
        />
        <Route
          path="/mobile/add-rfq-item/:id"
          element={<AddNewRFQItemMobilePage />}
        />
      </Routes>
    </Suspense>
  );
};

export default RoutesComponent;

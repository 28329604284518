import { Box, IconButton, Toolbar } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
// import Notifications from "components/Notifications/Notifications";
import StockToolbar from "./DesktopToolbars/StockToolbar";
import { useLocation } from "react-router-dom";

interface IDesktopAppToolbarProps {
  open: boolean;
  handleDrawerOpen: () => void;
}

const DesktopAppToolbar = ({
  open,
  handleDrawerOpen,
}: IDesktopAppToolbarProps) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex" }}>
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 1,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
      </Box>
      {pathname === "/stock" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <StockToolbar />
          {/* <DropdownStockMenu /> */}
        </Box>
      )}
      {/* TODO: revisit notifications */}
      {/* <Notifications /> */}
    </Toolbar>
  );
};

export default DesktopAppToolbar;
